import { template as template_d373394c82934859894cecd08d3170d4 } from "@ember/template-compiler";
const SidebarSectionMessage = template_d373394c82934859894cecd08d3170d4(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
