import { template as template_7c47a68e293e47f9a0a2660925e7a2dd } from "@ember/template-compiler";
const WelcomeHeader = template_7c47a68e293e47f9a0a2660925e7a2dd(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
