import { template as template_cc40b29d460b4c099a8e861d7b7e1f2a } from "@ember/template-compiler";
const FKLabel = template_cc40b29d460b4c099a8e861d7b7e1f2a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
