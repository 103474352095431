import { template as template_cf7ce3e9821d4c75bcefd406a5e4a0e4 } from "@ember/template-compiler";
const FKText = template_cf7ce3e9821d4c75bcefd406a5e4a0e4(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
