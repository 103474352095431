import { template as template_530bd08f489d455aaa16e4b013f3856b } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_530bd08f489d455aaa16e4b013f3856b(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
